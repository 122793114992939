@import "../variables";

$sizes: 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 22, 24, 25, 27, 30, 32, 35, 40, 50, 55, 75, 77;

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        @if $breakpoint == "xs" {
            @each $size in $sizes {
                .font-size-#{$size} {
                  font-size: #{$size}px !important;
                }
            }
        } @else {
            @each $size in $sizes {
                .font-size-#{$breakpoint}-#{$size} {
                    font-size: #{$size}px !important;
                }
            }
        }
    }
}

.justify-content-evenly {
    justify-content: space-evenly;
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        @if $breakpoint != "xs" {
            .justify-content-#{$breakpoint}-evenly {
                justify-content: space-evenly;
            }
        }
    }
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-weight-bolder {
    font-weight: 900 !important;
}

.font-weight-semi-bold {
    font-weight: 600 !important;
}
