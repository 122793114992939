@import "../variables";
@import "../utilities/globalmixins";

// product image section in PDP and Quickview
.carousel-container {
    .product-image-carousel {
        height: 100%;
        max-width: 50rem;
        overflow: hidden;
        padding-left: 3rem;
        padding-right: 3rem;

        .slick-initialized {
            height: auto;
            visibility: visible;
        }

        .slick-arrow {
            width: 45px;
            height: 45px;
            font-family: "FontAwesome";
            font-size: 1.5rem;
            color: var(--color-primary);

            &.slick-prev {
                left: 1%;

                &::after {
                    content: '\f053 ';
                }
            }

            &.slick-next {
                right: 1%;

                &::after {
                    content: '\f054 ';
                }
            }
        }
    }

    .product-thumbnail-slick {
        .slick-paging-btn {
            background-color: transparent;
            border: 0;
            cursor: pointer;
            opacity: 0.5;
            outline: none;
            max-width: 120px;
            margin: 1px 1px 8px 1px;
            padding: 0;

            .slick-paging-image-container {
                height: auto;
                width: 100%;
            }
            &:focus {
                outline: 0;
            }
        }

        .slick-current {
            opacity: 1;
        }
    }
}
