@import "../variables";

:root {
    /** ==================================================================== **/

    /** Create Custom CSS Variables from "sfra bootstrap override" variables **/

    /** ==================================================================== **/
    --color-gunmetal: #{#212529};
    --color-raisin-black: #{#212529};
    --color-sea-green: #{#279da7};
    --color-chinese-silver: #{$grey3};
    --color-gainsboro: #{#ddd};
    --color-arsenic: #{#3d4542};
    --color-dazzled-blue: #{#285d89};
    --color-web-orange: #{#ffa303};
    --color-isabelline: #{#f0f0ed};
    --color-cultured: #{#f5f5f5};
    --color-primary: var(--color-gunmetal);
    --color-cardinal-red: #{#c82032};
    --color-red: var(--color-cardinal-red);
    --color-japanese-carmine: #{#A02C39};
    // Fonts family,sizes
    --font-family-nunito-sans: 'Nunito Sans', serif;
    --font-family-birds-paradise: 'birds_of_paradise__personalRg', serif;

    // Text decorators
    --text-decoration-style: underline;

    /** ==================================================================== **/

    /** Define skin named variables here                                    **/

    /** ==================================================================== **/
    --skin-background-color-1: var(--color-white);
    --skin-background-color-2: var(--color-isabelline);
    --skin-background-color-3: var(--color-cultured);
    --skin-border-color-1: var(--color-white);
    --skin-border-color-2: var(--color-gainsboro);
    --skin-main-text-color-1: var(--color-primary);
    --skin-main-text-color-2: var(--color-raisin-black);
    --skin-menu-color-1: var(--color-primary);
    --skin-menu-color-1-invert: var(--color-primary);
    --skin-menu-color-2: var(--color-dazzled-blue);
    --skin-link-color-1: var(--color-primary);
    --skin-link-color-inverse: var(--color-white);
    --skin-link-color-2: var(--color-sea-green);
    --skin-primary-color-1: var(--color-primary);
    --skin-primary-color-2: var(--color-primary);
    --skin-primary-color-invert-1: var(--color-white);
    --skin-selectbox-background-color-1: var(--color-white);
    --skin-selectbox-text-color-1: var(--skin-main-text-color-1);
    --skin-banner-background-color-1: var(--color-white);
    --skin-banner-background-color-2: #222;
    --skin-banner-text-color-1: var(--color-white);
    --skin-heading-color-1: var(--color-primary);
    --skin-heading-color-1-invert: var(--color-white);
    --skin-price-1: var(--color-gray7);
    --skin-header-font: var(--font-family-nunito-sans);
    --skin-headers-font: var(--font-family-nunito-sans);
    --skin-body-font: var(--font-family-nunito-sans);
    --skin-headers-font: var(--font-family-nunito-sans);
    --skin-secondary-font: var(--font-family-birds-paradise);
    --skin-header-bg-color: var(--color-sea-green);
    --skin-header-search-text-color: var(--color-grey3);
    --skin-link-decoration-style: var (--text-decoration-style);
    --skin-btn-text-color: var(--color-white);
    --skin-btn-background-color: var(--color-dazzled-blue);
    --skin-btn-background-hover: var(--color-dazzled-blue);
    --skin-btn-border-color: var(--color-dazzled-blue);
    --skin-btn-tertiary-text-color: var(--color-white);
    --skin-btn-tertiary-background-color: var(--color-web-orange);
    --skin-btn-tertiary-background-hover: var(--color-web-orange);
    --skin-btn-tertiary-border-color: var(--color-web-orange);
    --skin-review-stars-color: var(--color-dazzled-blue);
}
