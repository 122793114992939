@import "bootstrap/scss/mixins/breakpoints";
@import "~providiobase/product/productSlick";
@import "../utilities/globalmixins";

// product image section in PDP and Quickview
.carousel-container {
    .product-thumbnail-slick {
        .slick-prev,
        .slick-next {
            font-size: $lg-font-size;
            color: var(--skin-primary-color-1);
            height: 35px;
            width: 35px;
            margin: auto;
            text-align: center;
        }

        .slick-next {
            bottom: -360px;
            left: 7px;
            right: 0;
        }

        .slick-prev {
            top: -10px;
            right: -10px;
            left: 0;
        }

        .slick-current {
            .img-fluid {
                transform: scale(1.2);
            }
        }
        .slick-paging-btn {
            margin-top: 0.5rem;
        }

        .slick-disabled {
            opacity: 0;
            pointer-events:none;
        }
    }
    .product-image-carousel {
        @include roundedSlickArrow;

        .img-fluid {
            width: 90% !important;
            margin: auto;

            @include media-breakpoint-down(md) {
                width: 100% !important;
            }
        }

        @include media-breakpoint-down(md) {
            .slick-prev {
                left: 25px;
            }
        }
    }
}
@include media-breakpoint-down(md) {
.product-detail {
    .image-container {
        padding: 0;

        .carousel-container {
            padding: 0;
        }
    }
}
}

.product-quickview {
    .product-thumbnail-slick {
        .slick-paging-image-container{
            .img-fluid {
                max-width: 80%;
            }
        }

        .slick-arrow {
            display: none;
        }
    }
    .product-image-carousel {
        .img-fluid {
            width: 100% !important;
        }
    }
}

.product-detail .in-stock {
    display: none;
}