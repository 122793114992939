@import "~base/components/hero";
@import "../variables";

.slant-down {

    @include media-breakpoint-up(sm) {
        &::after {
            border-left-width: 0;
        }
    }
}

.slant-up {

    @include media-breakpoint-up(sm) {
        &::after {
            border-right-width: 0;
        }
    }
}

@mixin zerovalue() {
    width: 0;
    left: 0;
}

.hero {
    height: 15vw;

    h1.page-title {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: transparent;
        font-size: 1.875rem;
        text-transform: uppercase;
        padding: 0;
        margin: 0;

        &::before {
            @include media-breakpoint-only(xl) {
                @include zerovalue();
            }

            @include media-breakpoint-only(lg) {
                @include zerovalue();
            }

            @include media-breakpoint-only(md) {
                @include zerovalue();
            }
        }
    }
}

