
.cat-thumbnail-image {
    display: none;

    @include media-breakpoint-up(lg) {
        display: inline-block;
    }
}

.navbar-expand-lg {
    .navbar-nav {
        .dropdown-menu {

            @include media-breakpoint-up(lg) {
                &.products,
                &.brands {
                    .container {
                        justify-content: flex-start;
 
                        .dropdown-item {
                            width: 25%;

                            &.dropdown {
                                margin-bottom: 20px;
                            }

                            h5 {
                                text-align: left;
                            }
                        }
                    }
                }

                .container {
                    width: 1235px;
                    justify-content: center;
                    -ms-flex-pack: center;
                    margin: auto;
                    padding: 1rem 0;

                    &.row {
                        max-width: 90%;
                    }
                }

                .menu-image-container {
                    text-align: center;

                    li {
                        width: auto;
                        margin: 0;
                        float: left;
                        clear: none;
                    }

                    a.dropdown-link {
                        img {
                            padding: 1rem;
                        }

                        &:hover {
                            img {
                                transform: scale(1.1);
                                transition: transform 0.5s ease;
                            }
                        }
                    }
                }

                &.brands {
                    .dropdown-item {
                        h5 {
                            display: none;
                        }
                    }
                }

                // custom css for diet plan category
                &.diet-dropdown-menu {
                    padding: 0;

                    .container {
                        .dropdown-item {
                            padding: calculateRem(24px) 1rem calculateRem(30px) 1rem;
                            width: 25%;
                            font-size: calculateRem(19px);
                            text-align: center;
                            color: $black;

                            &.diet-dropdown-menu-asset {
                                position: absolute;
                                top: 1.5rem;
                                left: 0;
                                width: 100%;
                                text-transform: uppercase;
                                padding: 0;
                                margin: 0;
                                a {
                                    font-size: 1rem;
                                    font-weight: 700;
                                    color: #000000 !important;
                                }
                            }

                            &:not(.diet-dropdown-menu-asset) {
                                border: 1px solid transparent;
                                border-top-width: 0;
                                border-bottom-width: 0;
                                transition: 0.3s all ease-in-out;
                            }

                            a {
                                font-size: calculateRem(31px);
                                color: $black !important;
                                margin: 0;
                                img {
                                    margin-bottom: 0.5rem;
                                }
                            }
                        }

                        .diet-menu-view-all {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            font-size: calculateRem(31px);

                            svg {
                                margin-top: calculateRem(30px);
                            }
                        }
                    }
                }
            }

            .container {
                // non image cat
                @include media-breakpoint-down(md) {
                    justify-content: left;
                    margin: 0;
                    padding: 0;

                    .dropdown-item {
                        width: 100%;
                        border-top: 1px solid $grey2;
                    }

                    .diet-menu-view-all {
                        padding: 10px 0 0 15px;
                        display: block;
                    }
                }
            }
        }

        .view-all-link {
            color: var(--color-white) !important;
        }
    }
}

        .pet-health {    
            .save-dog {             
                font-size: 1rem;
                font-weight: 700;
                color: #228087 !important;

                a.diet-menu-view-all {
                    color: #228087 !important;
                    font-size: 1.5rem!important;

                    @include media-breakpoint-down(lg) {
                        padding: 0px 0 0 15px!important;
                        font-size: 1rem!important;
                        border-top: 1px solid #eee;
                        line-height: 3;
                    } 
                }

                @include media-breakpoint-up(lg) {
                    position: absolute;
                } 
            }
        }
.multilevel-dropdown {
    .dropdown-item {
        .dropdown-link {
            @include media-breakpoint-up(lg) {
                padding: 0;
            }

            @include media-breakpoint-down(md) {
                padding: 10px 0 0 15px;
            }
        }
    }

    .dropdown-menu {
        top: 98%;
    }
}

.menu-toggleable-left {
    &.in {

        @include media-breakpoint-down(md) {
            right: initial;
        }

        @include media-breakpoint-down(xs) {
            right: 0;
        }
    }
}

