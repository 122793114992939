@import "../utilities/globalmixins";

footer {
    background-color: var(--skin-background-color-2);

    a {
        color: var(--skin-main-text-color-2);

        &:hover {
            color: var(--skin-main-text-color-2);
        }
    }

    .social-links {
        li {
            display: inline-block;
            margin: 0.313em 0;
            margin-right: 1.5rem;

            a {
                opacity: 0.6;
                transition: opacity 0.2s ease-out;
                font-size: 1.5rem;

                &:hover {
                    opacity: 1;
                }

                .fa-facebook:hover {
                    color: $fb-blue;
                }

                .fa-instagram:hover {
                    color: var(--skin-main-text-color-2);
                }

                .fa-twitter:hover {
                    color: $twitter-blue;
                }

                .fa-pinterest:hover {
                    color: $pin-red;
                }

                .fa-app-store:hover {
                    color: var(--skin-main-text-color-2);
                }
            }

            @include media-breakpoint-down(xs) {
                width: 12%;
                text-align: left;
                margin-right: .25rem;
            }
        }

        @include media-breakpoint-down(xs) {
            width: 100%;
        }
    }

    .copyrights-content {
        a {
            color: var(--skin-primary-color-1);

            &:hover {
                color: var(--skin-primary-color-1);
            }
        }
    }
}

.newsletter {
    padding-right: 3rem;
    padding-bottom: 3rem;
    border-right: 1px solid rgba(255, 255, 255, 0.4);
}

.footer-logo {
    h6 {
        font-weight: 900;
        font-size: 30px;
        letter-spacing: 5px;
    }
}

.footer-info {
    p {
        color: var(--skin-main-text-color-2);
        font-size: 0.75rem;
    }

    h5 {
        color: var(--color-primary);
        font-weight: 600;
        font-size: 0.75rem;
    }

    .input-group-text {
        padding: 0 0.5rem;
        color: var(--color-primary);
        background-color: var(--skin-main-text-color-2);
        border: transparent;
    }

    input {
        border: transparent;
        margin-left: 1px;
        box-shadow: none;

        &:focus {
            border: transparent;
            box-shadow: 0 0 0;
        }
    }

    .input-group-append button {
        color: var(--skin-main-text-color-2);
        font-size: 0.75rem;
        background-color: var(--color-primary);
        border-color: var(--color-primary);
    }

    @include collaspe-toggle(var(--color-white),180deg);
}

.collapse:not(.show) {
    display: none;
}

.copyrights {
    background-color: var(--skin-background-color-3);
    color: var(--skin-main-text-color-2);
    font-size: 0.75rem;
    overflow: hidden;

    img {
        height: 2.5rem;
    }

    .copyrights-logo {
        text-align: right;
        border-right: 1px solid rgba(255, 255, 255, 0.4);
        padding-right: 3rem;
    }
}

.copyrights-content {
    ul {
        li {
            display: inline-block;
            margin-right: 0.5rem;
        }
    }
}

@include media-breakpoint-up(md) {
    .footer-info form {
        margin-bottom: 3rem;
    }

    .footer-info ul {
        margin-bottom: 0;
    }
}

@include media-breakpoint-down(md) {

    .footer-info {
        padding-bottom: 1rem;
    }

    .footer-info h5 {
        color: var(--skin-main-text-color-2);
    }

    .newsletter {
        border-right: transparent;
        padding: 0 1rem;
        max-width: 540px;
    }

    .newsletter h5 {
        color: var(--color-primary);
    }

    .copyrights .copyrights-logo {
        text-align: center;
        border-right: transparent;
        padding-right: 0;
    }
}

.scroll-icon {
    position: fixed;
    z-index: 9;
    bottom: 40px;
    right: 1.375rem;
    font-size: 1.25rem;
    background: #fff;
    width: 2.5rem;
    height: 2.5rem;
    margin-bottom: 1.25rem;
    box-shadow: 0 0 6px var(--color-gray);

    @include border-radius (50%);

    i {
        color: var(--color-primary);
    }
}

@include media-breakpoint-down(sm) {

    footer {
        > .container {
            max-width: 540px;
        }
    }

    .newsletter {
        padding: 0;
        max-width: initial;
    }
}

#MCSubscriptionForm {
    @include media-breakpoint-down(sm) {
        margin-bottom: 0;
    }

    .input-group-prepend {
        input {
            background-color: $white;
            margin-left: 0;
        }
    }
}
