@each $color in map-keys($swatch-colors) {
    $colorValue: #{map-get($swatch-colors, $color)};

    .swatch-circle-#{$color} {

        @include circle(2em, $colorValue);

        border: 0.063rem solid rgba(0, 0, 0, 0.1);
        display: block;
        position: relative;

        &:hover {
            border: 0.063rem solid rgba(0, 0, 0, 0.3);
        }

        &.disabled {
            opacity: 0.2;
        }
    }

    .swatch-filter-#{$color} {

        @include circle(1.38em, $colorValue);

        background-color: $colorValue;
        display: block;
        position: relative;
        border-radius: 0;

        &.disabled {
            opacity: 0.2;
        }
    }
}

.swatch-circle-miscellaneous {
    transform: rotate(0deg);
    width: 2em;
    height: 2em;

    &.selected::after {
        transform: rotate (0deg);
    }
}

@mixin swatch-check-mark() {
    &.color-value.selected::after {
        color: $grey2;
        content: "\F00C";
        font-size: 1.2em;
        background: transparent;
        height: 0.5em;
        left: 6px;
        line-height: 1;
        top: 5px;
        width: 0.5em;
    }
}

.swatch-mark {
    @include swatch-check-mark();
}
