@import "~providiobase/components/productCommon";

@mixin product-page-text {
    font-size: 1rem;
    text-transform: initial;
}

.product-number,
.product-breadcrumb .breadcrumb,
.quantity label,
.attribute label,
.attribute .non-input-label {
    @include product-page-text();
}

.attribute label {
    &.flavor {
        text-transform: capitalize;
    }
} 
.product-breadcrumb {

    .breadcrumb {
        @include media-breakpoint-down(md) {
            border-top: 0;
            padding-bottom: 0;
        }

        li:last-child a {
            color: var(--color-dazzled-blue) !important;
        }
    }
}

.product-number {
    text-transform: uppercase;
    color: var(--color-primary);
}

h1 {
    &.product-name {
        font-size: 1.8rem;
    }
}

.ratings {
    #pr-reviewsnippet,
    #pr-questionsnippet {
        margin-bottom: 0;
    }

    .pr-snippet-stars-container {
        float: left;
    }

    .pr-snippet-stars-reco-reco {
        display: none;
    }
}

.prices-add-to-cart-actions {

    @include media-breakpoint-up(sm) {
        padding: 0;
    }
}

button {
    &.add-to-cart,
    &.add-to-cart-global,
    &.update-cart-product-global {
        font-size: 1.125rem;
        text-transform: capitalize;
        border-radius: 0;
        font-weight: 600;

        i {
            display: none;
        }
    }
}

.cart-and-ipay {

    @include media-breakpoint-only(xs) {
        padding-bottom: 0;
    }
}

.description-and-detail {
    margin-bottom: 2rem;
}

.product-description {
    .toggle:after,
    .collapsed .toggle:before {
        width: 1rem;
        border-bottom-width: 3px;
    }

    .toggle {
        width: 1rem;
        height: 1rem;
    }

    .description-header {
        border-bottom: 2px solid $grey3;
    }
}

.description-label {
    text-transform: none;
}

.details {
    .long-description {
        padding: 1.5rem 1rem;
    }
    

    .description-label {
        display: none;
    }
}

.description {
    ul {
        padding-inline-start: 1rem;

        li {
            margin-bottom: 0.5rem;
            margin-right: 0.5rem;
        }

        @include media-breakpoint-up(sm) {
            float: left;
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: 0;
        }
    }
}

.main-attributes {
    margin: 0;
}

.quick-view-dialog,
.choose-bonus-product-dialog {
    max-width: 76vw;
}

.attributes {
    select {
    font-weight: 600;
    font-size: 1rem;
    color: var(--color-main-text-color-1);

        &:focus {
            font-size: 1rem;
        }

        option {
            @extend select;

            &.unselectable  {
                color: $grey4;
            }
        }
    }
}

