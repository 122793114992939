@import "~base/components/common";
@import "bootstrap/scss/mixins/breakpoints";
@import "~base/components/toastMessage";

abbr[title] {
    cursor: unset;
}

img {
    max-width: 100%;
}

.text-reset {
    color: var(--skin-link-color-1);
}

.experience-component {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.font-weight-bold {
    font-weight: 600 !important;
}

.source-sans-pro {
    font-family: $base-font-family;
}

.dosis-sans {
    font-family: $dosis-sans-serif;
}

dl {
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
}

dt {
    clear: both;
    float: left;
    font-weight: 600;
    margin-right: 5px;
    padding: 0 0.3rem 0 0;
}

header ~ #maincontent .container a:not(.btn-primary, .btn-outline-primary) {
    color: var(--skin-link-color-1);
}

.page,
.checkout {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
}

.form-control {
    color: var(--skin-input-text-color);
    border: 0;
    border-radius: 0;
    box-shadow: inset 0 -1px 0 #ddd;
    background-color: transparent;
}

.form-group {
    margin-bottom: 1.875rem;
}

.input-group {
    .form-control {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.form-control.is-invalid:focus {
    border-color: var(--color-error-red);
    -webkit-box-shadow: inset 0 -2px 0 var(--color-error-red);
    box-shadow: inset 0 -2px 0 var(--color-error-red);
}

.add-to-wishlist-messages {
    @include toast-message();
    top: 30%;
    z-index: 3;

    .add-to-wishlist-alert {
        padding: 0.75rem 1.25rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;
        border-radius: 0.1875rem;
    }
}

.hide-product-discount {
    display: none;
}

.product-discount {
    color: $success;
}

.product-discount {
    color: var(--color-red);
}

.home-main-categories {
    a {
        margin: 2px;
        display: block;
    }
}

.slick-initialized .slick-slide,
.slick-slider {
    height: auto !important;
}

.strike-throughPrice {
    text-decoration: line-through;
    color: #999;
    margin-right: 0.938rem;
}

.wishlist-line-item-divider {
    display: none;
}

.gift-card-wrapper,
.gift-cert-wrapper,
.gift-cert-fields {
    .error {
        font-size: 80%;
        color: var(--color-error-red);
    }
}

.remove-to-basket-alert {
    @include toast-message();
    top: 30%;
    z-index: 11;
}

.address-form {
    select {
        &.form-control {
            padding-left: rem(12px);

            &:focus {
                padding-left: rem(12px);
            }
        }
    }
}
