.ratings {

    div[id^="category-snippet"] {
        display: flex;
    }

    .p-w-r {
        color: inherit;
        font-family: inherit;
        margin: 0;

        .pr-snippet-rating-decimal {
            display: none;
        }

        // hides QA section on PDP
        .pr-qa-snippet-container {
            display: none;
        }

        a {
            color: var(--color-dazzled-blue) !important;
            cursor: pointer;
        }

        .pr-snippet-stars-reco-inline {
            .pr-snippet-read-and-write {
                margin-top: 3px !important;

                a,
                span {
                    font-size: 1rem !important;
                    
                }
            }

            &.pr-snippet-minimal {
                .pr-snippet-read-and-write a {
                    margin: 0px 0.75rem 0;
                }
            }

            .pr-snippet-stars-reco-stars .pr-snippet {
                display: flex;
                align-items: center;
            }
        }
    }

    .pr-category-snippet__rating,
    .pr-category-snippet__total {
        float: left;
        line-height: 18px;
    }
}

.p-w-r {
    .pr-star-v4 {
        background: none !important;
        padding: 0 !important;
        height: 16px !important;
        width: 16px !important;

        &::before {
            position: absolute;
            font-family: "FontAwesome";
            content: '\f005';
            font-size: 1rem;
            color: var(--skin-review-stars-color);
            z-index: 1;
            overflow: hidden;
        }
    }

    .pr-star-v4-75-filled:before {
        width: 11px;
    }

    .pr-star-v4-50-filled:before {
        width: 8px;
    }

    .pr-star-v4-25-filled:before {
        width: 5px;
    }

    .pr-star-v4-0-filled:before {
        width: 0;
    }

    .pr-star-v4-0-filled,
    .pr-star-v4-25-filled,
    .pr-star-v4-50-filled,
    .pr-star-v4-75-filled {
        &::after {
            position: absolute;
            font-family: "FontAwesome";
            content: '\f005';
            color:var(--color-gainsboro);
            font-size: 1rem;
        }
    }

    .pr-review-snapshot {
        .pr-ratings-histogram {
            .pr-ratings-histogram-barValue {
                background: #0b7bc1 !important;
            }
        }

        .pr-snippet {
            .pr-snippet-stars-png {
                display: flex;
                align-items: center;
            }
        }

        .pr-review-snapshot-header {
            a {
                display: none !important;
            }
        }
    }

    .pr-media-modal {
        .button__close {
           top: 50px !important;
        }
    }

    .pr-category-snippet,
    .pr-snippet .pr-snippet-stars-png  {
        display: flex !important;
        align-items: center;
    }

    .pr-submit .pr-logo-container {
        display: none;
    }
}

.product-tile {
    .p-w-r {
        .pr-category-snippet__item {
            text-align: left !important;
        }

        .pr-snippet {
            div,
            span {
                line-height: 15px;
            }
        }
    }
}

.product-price-rating {
    .pr-snippet-write-review-link {
        display: none !important;
    }
}

.pr-review-snapshot-block {
    .pr-snippet-write-review-link {
        background-color: var(--color-dazzled-blue) !important;
        color: var(--color-white) !important;
    }
}

#pr-image-display {
    display: none ;
}

.p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-flag-review-container .pr-rd-flag-review-btn {
    background: none;
    border: none;
    color: var(--color-dazzled-blue);
}

.pr-rd-main-footer a {
    color: var(--color-dazzled-blue) !important;
}

.diet-product-detail {
    #pr-questionsnippet {
        display: none;
    }

    .p-w-r {
        .pr-snippet-stars-reco-inline {
            &.pr-snippet-minimal {
                .pr-snippet-read-and-write a {
                    margin: 0px 5px;
                }

                .pr-snippet-stars-reco-reco {
                    display: none;
                }
            }

            .pr-snippet-stars-reco-stars .pr-snippet {
                display: flex;
                align-items: center;
            }
        }

        .pr-snippet .pr-snippet-stars-png .pr-snippet-rating-decimal {
            display: none;
        }
    }

    .pr-snippet-read-and-write .pr-snippet-write-review-link {
        display: none;
    }
}

.p-w-r .pr-qa-display {
    .pr-btn-answer .pr-cross-icon,
    .pr-btn-ask-question .pr-cross-icon {
        z-index: 9 !important;
    }
}

.pr-custom-write-review-form {
    display: flex;
    justify-content: center;
    padding: 1.5rem;
}
