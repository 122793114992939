@import "../variables";

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--skin-headers-font);
}

h1 {
    font-size: $heading-1-font-size;
}

h2 {
    font-size: $heading-2-font-size;
}

h3 {
    font-size: $heading-3-font-size;
    text-align: center;
}

h4 {
    font-size: $heading-4-font-size;
}

h5 {
    font-size: $heading-5-font-size;
}

@include media-breakpoint-down(lg) {
    h1 {
        font-size: calc(1.325rem + 0.9vw);
    }

    h2 {
        font-size: calc(1.3rem + 0.6vw);
    }

    h3 {
        font-size: calc(1.275rem + 0.3vw);
    }
}
