@import "~providiobase/components/footer";

.footer-info {
    @include collaspe-toggle(var(--color-primary),180deg);
    border-right: none;

    .input-group-text {
        padding: 0 0.5rem;
        background-color: transparent;
        border-right: 1px solid var(--color-primary);
    }

    .input-group-append {
        margin: -1px;

        button {
            color: var(--color-white);
        }
    }

    .input-group-prepend {
        margin: -1px;
    }

    .input-group {
        border: 1px solid var(--color-primary);
    }
}

#fileUploadForm {
    display: none;
}

.email-signup-message {
    top: 25%;
}

.scroll-icon {
    @include media-breakpoint-down (xs) {
        bottom: 100px;
    }
}

footer .scroll-top .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}
