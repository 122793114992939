@import "~providiobase/components/productTiles";
@import "./productCommon";

.product { 
    @include media-breakpoint-only(lg) {
        max-width: 17.75em;
    }
}

.product-tile {
    display: flex;
    flex-direction: column;
    &:hover {
        .image-container {
            .quickview {
                color: var(--color-primary);

                &:hover {
                    color: var(--color-white);
                }
            }

            .add-to-cart {
                color: var(--color-primary);
                background-color: var(--color-white);
    
                &:hover {
                    color: var(--color-white);
                    background-color: var(--color-primary) !important;
                }
            }

            .btn {
                @include media-breakpoint-down(lg) {
                    font-size: 0.6rem;
                }
            }
        }
    }

    .image-container {
        /* removed the border as per the defect  DDSI-261 */
        min-height: 100px;

        // Hides wishlist button on p tiles
        a {
            &.wishlistTile {
                display: none;
            }
        }

        .add-to-cart {
            opacity: 0;
            background-color: var(--color-white) !important;
        }
        
        .badges {
            position: absolute;
            top: 2rem;
            left: -40px;
            line-height: 25px;
            transform: rotate(-45deg);
            padding: 0.313rem 0.625rem;
            width: 180px;
            -ms-flex-pack: center;
            justify-content: center;
            color: #fff;

            &.new{
                background-color: $sea-green;
            }

            &.sale{
                background-color: $Japanese-Carmine;
            }
        }
    }

    .tile-body {
        .ratings,
        .price {
            margin-bottom: 0.715rem;
            min-height: 16px;
        }

        .counts {
            font-size: 1em;
            margin-bottom: 0.715rem;
            min-height: 24px;
        }
    
        .pdp-link {
            justify-content: flex-start;

            a {
                &.link {
                    display: -webkit-box;
                    /* autoprefixer: off */
                    -webkit-box-orient: vertical;
                    /* autoprefixer: on */
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-align: left;
                    margin-bottom: 0.715rem;
                    height: 38px;

                    @include media-breakpoint-down(sm) {
                        height: 33px;
                    }
                }
            }
        }
    }

    .fa-star::before,
    .fa-star-half-o::before,
    .fa-star-o::before {
        color: var(--skin-review-stars-color);
    }

    .product-tile-inventory.outofstock {
        color: #A02C39;
        height: 2.5rem;
    }
}

.price {
    .strike-through {
        font-size: 1rem;
        margin-right: 0.5rem;
        color: #555;
    }

    .discount-price {
        .sales {
            color: var(--color-japanese-carmine);
        }
    }
}

.order-discount,
.shipping-discount {
    color: #A02C39;
}

.einstein .product-tile .image-container a {
    &.image-tiles {
        height: 250px;
        overflow: hidden;
    }

    &.quickview {
        display: none;
    }
}  

.diet-list {
    ul{
        margin: 0;
        list-style: none;
        padding: 0 1.5rem;
        line-height: 1.6875rem;
        li {
            position: relative;
            padding-left: 50px;
            &::before {
                background-image: url('../images/tickicon.png');
                background-repeat: no-repeat;
                content: '';
                width: 14px;
                height: 9px;
                position: absolute;
                left: 0;
                top: 9px;
                background-size: contain;
            }
        }
    }
    @include media-breakpoint-up(lg) {
        ul {
            line-height: 2.8125rem;
            li {
                &::before {
                    width: 24px;
                    height: 19px;
                    margin-right: 25px;
                    top: 14px;
                }
            }
        }
    }
}

.outOfStock {
    color: $dark-red;
}

@include media-breakpoint-up(md) {
    .carousel .carousel-inner {
        justify-content: center;
    }
}

// Working for device width between 575px & 769px 
// Carousel need to have specific slide count mobile[1], tablet[3], desktop[4]
.custom-breakpoint .carousel-item {
    margin-right: 0 !important;
}
