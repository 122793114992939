// minicart from providio base loads catridge path

.minicart {
    margin-top: 0;

    .popover {
        &.show {
            @include media-breakpoint-down(sm) {
                width: 90%;
                min-width: initial;
            }
        }
    }

    .minicart-quantity {
        background: transparent;

        @include media-breakpoint-up(lg) {
            color: var(--color-white);
        }
    }

    .product-summary {
        max-height: 65vh;
    }

    .checkout-btn {
        &.btn-primary {
            display: none;
        }
    }

    h1 {
        font-weight: bold;
    }

    .line-item-pricing-info {
        font-weight: bold;
    }
}

.minicart-link {
    i {
        @include media-breakpoint-up(lg) {
            color: var(--color-white);
        }
    }
}

.minicart-progressbar-wrapper {
    .minicart-progressbar {
        display: block;
        width: 100%;
        background: #ddd;
        height: 20px;
        margin-bottom: 1rem;
        position: relative;
        border-radius: 3rem;

        .progress-indicator {
            background-color: var(--color-dazzled-blue);
            max-width: 100%;
            position: absolute;
            display: block;
            left: 0;
            height: 100%;
            border-radius: 3rem;
        }
    }

    .success-bar {
        display: block;
        width: 100%;
        background-color: var(--color-dazzled-blue);
        height: 20px;
        margin-bottom: 1rem;
        position: relative;
        border-radius: 3rem;
    }

    .single-approaching-discount,
    .message {
        display: inline-block;
        width: 100%;
        margin-bottom: 5px;
    }
}

.single-approaching-discount {
    border: 0;

    a {
        font-size: .875rem !important;
        color: var(--color-dazzled-blue) !important;
        text-transform: capitalize !important;
    }
}
