.title-with-arrow-border {
    &-item {
        position: relative;
        margin-bottom: 30px;
        @include media-breakpoint-down(md) {
            margin-bottom: 7.5px;
        }
        &:after, &:before {
            top: 100%;
            left: 50%;
            border: solid transparent;
            content: "";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        &:after {
            border-color: rgba(255, 255, 255, 0);
            border-top-color: $white;
            border-width: 30px;
            margin-left: -30px;
            margin-top: -2px;
            @include media-breakpoint-down(md) {
                margin-top: -1px;
                border-width: 7.5px;
                margin-left: -7.5px;
            }
        }
        &:before {
            border-color: rgba(112, 112, 112, 0);
            border-top-color: $grey11;
            border-width: 30px;
            margin-left: -30px;
            @include media-breakpoint-down(md) {
                border-width: 7.5px;
                margin-left: -7.5px;
            }
        }
    }
    .editorialRichText-component-container:before {
        content: '';
        height: 2px;
        max-width: 314px;
        width: 100%;
        background: $grey11;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        @include media-breakpoint-down(md) {
            height: 1px;
            bottom: -1px;
            max-width: 95px;
        }
    }
}

.title-with-center-border {
    &-item {
        position: relative;
        display: flex;
        justify-content: center;
        &::before {
            background: $grey11;
            content: '';
            height: 1px;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 0;
        }
        .editorialRichText-component-container {
            padding: 0 1rem;
            position: relative;
            z-index: 1;
            background: $white;
        }
    }
}

.text-inline {
    display: flex !important;
    justify-content: center;
    align-items: center;
    [class^="icon-"] {
        margin-right: 8px;
        @include media-breakpoint-up(lg) {
            margin-right: 15px;
        }
    }
}
