@import "../variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "../utilities/globalmixins";

.embeddedServiceHelpButton {
    z-index: 10 !important;

    .helpButton {
        @include media-breakpoint-only(xs) {
            bottom: 70px !important;
        }

        .uiButton {
            background-color: var(--skin-btn-background-color) !important;
            font-family: var(--skin-header-font);

            &:focus {
                outline: 1px solid var(--skin-btn-border-color) !important;
            }
        }

        .helpButtonEnabled:hover::before,
        .helpButton .helpButtonEnabled:focus::before {
            opacity: 1 !important;
            background-color: var(--skin-btn-background-color) !important;
        }
    }
}

.embeddedServiceSidebarMinimizedDefaultUI .minimizedText,
.embeddedServiceSidebarForm .fieldList .uiInputText input,
.embeddedServiceSidebarFormField .uiInput .uiLabel-left,
.embeddedServiceSidebarFormField .slds-style-select,
.embeddedServiceSidebarFormField .slds-style-inputtext,
.embeddedServiceSidebarFormField .uiInputSelect > select.select,
.embeddedServiceSidebarFormField .uiInputDefaultError .form-element__help,
.embeddedServiceLiveAgentStateChatInputFooter .chasitorText,
.embeddedServiceSidebarButton,
.embeddedServiceSidebar.modalContainer {
    font-family: var(--skin-header-font) !important;
}


// block level style override
[embeddedservice-chatheader_chatheader-host] {
    background-color: var(--skin-primary-color-1) !important;
}

button[embeddedService-chatHeader_chatHeader] {
    font-size: 10px !important;
}

.embeddedServiceSidebarButton,
.embeddedServiceSidebarMinimizedDefaultUI.helpButton,
.embeddedServiceSidebarMinimizedDefaultUI.minimizedContainer:focus {
    background: var(--skin-btn-background-color) !important;
    border-color: var(--skin-btn-background-color) !important;

    &:hover {
        background: var(--skin-btn-background-hover) !important
    }
}

// esw-min override
@media only screen and (min-width: 48em) {
    .embeddedServiceHelpButton {
        .helpButton {
            bottom: 7px !important;

            .uiButton {
                border-radius: 8px;
                &:hover {
                    &::before {
                        border-radius: 8px;
                    }
                }
                &:focus {
                    &::before {
                        border-radius: 8px;
                    }
                }
            }
        }
    }
}
