@import "~base/components/productCommon";

@mixin product-page-text {
    color: var(--color-main-text-color-1);
    font-weight: 600;
    font-size: 0.8125rem;
    text-transform: uppercase;
}

// text with same size added to group
.product-number,
.product-breadcrumb .breadcrumb,
.quantity label,
.attribute label,
.attribute .non-input-label {
    @include product-page-text();
}

.price {
    @include media-breakpoint-down(lg) {
        font-size: 1.1rem;
    }
}

.product-price-rating .ratings {
    color: var(--color-primary);
    margin-top: 0.625em;
}

.size-chart a {
    color: var(--color-primary);
}

.availability-msg {
    font-weight: 600;
}

.product-detail {
    .attributes {
        .swatch-circle {
            background-clip: content-box;
            background-position: center;
            border: 1px solid transparent;
            padding: 2px;
            display: inline-block;
            height: 2.5rem;
            width: 2.5rem;
            @include border-radius(0);

            &.selected {
                border-color: #ababab;

                &::after {
                    display: none !important;
                }
            }
        }

        .swatch-attr-style {
            width: 30%;
            height: auto;
            border: 1px solid $grey9;
            padding: .5rem .375rem;
            border-radius: 100px;
            text-align: center;
            font-size: .85rem;

            .swatch-circle {
                width: 1rem;
                height: 1rem;
            }

            span {
                display: inline-block;
                vertical-align: middle;
            }

            &:hover {
                box-shadow: 0 7px 14px rgba(0,0,0,0.1);
            }

            &.selected-swatch {
                cursor: default;
                border: 1px solid $black;

                &:hover {
                    box-shadow: none;
                }
            }

            &.unselectable {
                position: relative;
                border-color: $black;
                background-color: $grey9;
                cursor: not-allowed;

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 1.125rem;
                    bottom: 0;
                    left: 1.125rem;
                    background: linear-gradient(to left top,transparent 48%,$black,transparent 51%);
                }
            }
        }

        .size-change {
            span {
                border: 1px solid $grey9;
                margin-right: 10px;
                padding: 5px 8px;
                margin-bottom: 5px;
                cursor: pointer;
                font-size: .75rem;
                position: relative;

                &.selected,
                &:hover {
                    border-color: $black;
                }

                &.disabled,
                &[disabled = "disabled"] {
                    pointer-events: none;

                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background: linear-gradient(to left top,transparent 48%,$black,transparent 51%);
                    }
                }
            }
        }

        .custom-select {
            color: var(--skin-selectbox-text-color-1);
        }
    }

    .color-attribute {
        height: 2.5rem;
        margin-right: 0.5rem;
        padding: 0;
        width: 2.5rem;
        &:focus {
            outline: 2px dotted;
        }
    }
}

.prices-add-to-cart-actions {
    padding-left: 0;
    padding-right: 0;
    margin-top: 1rem;

    @include media-breakpoint-down(xs) {
        background-color: var(--skin-background-color-1);
        padding: 0.5rem;
        z-index: 10 !important;
    }
}

button {
    &.add-to-cart,
    &.add-to-cart-global,
    &.update-cart-product-global {
        font-size: 0.75rem;
        text-transform: uppercase;
    }
}

.social-container {
    .social-icons{
        a{
            padding: 0 5px !important;
            margin-right:  0.2em;
            i {
                color: #ababab;
                padding: 0 !important;
            }
        }
    }
}

.size-chart-modal {
    h2 {
        font-weight: 500;
        font-size: rem(32px);
    }
}

.container.product-detail {
    &.set-item {
        margin-bottom: 0;
    }
}

// only for first set item
.set-items > .set-item {
    margin-top: 0.5rem !important;
}

.set-description {
    .description-and-detail {
        margin-top: 1rem;
        margin-bottom: 2em;
    }

    @include media-breakpoint-down(sm) {
        padding: 0;
    }
}

.add-to-cart-messages {
    z-index: 11;
}
