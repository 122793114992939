@import "menuCustom";

.multilevel-dropdown {
    .dropdown-menu {
        top: 90%;
        border: 0;
        border-radius: 0;

        @include media-breakpoint-up($next-breakpoint) {
            box-shadow: 0 3px 5px rgba(43, 36, 25, 0.4);
        }
    }

    .dropdown-item.dropdown > .dropdown-toggle::after {
        @include caret-right();
    }

    .dropdown-menu > .dropdown > .dropdown-menu {

        @include media-breakpoint-up($next-breakpoint) {
            top: -0.65em;
            left: 99%;
        }
    }

    .navbar > .close-menu > .back {
        display: none;
    }

    .close-menu .back {
        .caret-left {
            @include caret-left();
        }
    }

    .dropdown-item {
        > .dropdown-link {
            display: block;
            padding: 0;
            margin: 0 0 10px;
            text-transform: uppercase;
            color: var(--skin-menu-color-blue);
            font-weight: 600;
            font-size: 0.875rem;
        }

        &.top-category {
            font-weight: bold;

            > .nav-link {
                padding-left: 0;
            }
        }
    }
}

.menu-toggleable-left {
    .close-menu {
        @include media-breakpoint-up(sm) {
            display: block;
        }
        @include media-breakpoint-up(lg) {
            display: block;
        }
    }
}

.multilevel-dropdown {
    .dropdown-item {
        background: none;

        + .dropdown-item {
            border: none;
        }
    }

    .dropdown-item.dropdown > .dropdown-toggle {
        &::after {
            @include caret-right();
        }
    }

    .dropdown-item {
        ul {
            &.dropdown-menu {
                @include media-breakpoint-up(lg) {
                    position: static;
                    border: none;
                    box-shadow: none;
                    opacity: 1;
                    display: block;
                    visibility: visible;
                    padding: 0;
                    margin-top: 15px;
                }

                .container {
                    flex-direction: column;
                    padding: 0;
                }

                .dropdown-link {
                    padding: 0;
                    margin: 0 0 7px;
                    color: var(--color-link-color);
                    text-transform: none;
                    font-weight: 400;

                    @include media-breakpoint-down(md) {
                        padding: 10px 0 0 10px;
                    }
                }
            }
        }
    }
}

.level-1 {
    @include media-breakpoint-up(md) {
        > .nav-item {
            &:hover {
                border-bottom: 4px solid var(--skin-menu-color-blue);
            }
        }
    }
}

.navbar-expand-lg {
    position: static;

    .navbar-nav {
        .dropdown {
            position: static;
        }

        a {
            &.level-1 {
                li {
                    a {
                        font-size: 14px;
                    }
                }
            }
        }

        .dropdown-menu {
            width: 100%;

            @include media-breakpoint-up(lg) {
                opacity: 0;

                &.show {
                    opacity: 1;
                    visibility: visible;
                }
            }

            .container {
                justify-content: center;
                align-items: baseline;

                .dropdown-item {
                    width: 200px;
                    padding: 0;
                    border: none;
                }
            }
        }
    }
}

.dropdown-toggle {
    @include media-breakpoint-up(lg) {
        &::after {
            display: none;
        }
    }
}

.site-header {
    @include media-breakpoint-up(lg) {
        .right-nav {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            max-width: 11rem;
        }

        .main-nav,
        .navbar-nav,
        .navbar-inner,
        .nav-item {
            height: 100%;
        }
    }

    @include media-breakpoint-up(xl) {
        .right-nav {
            max-width: initial;
        }
    }

    .main-nav {
        margin-bottom: -1px;
        border: 0;
    }
}

.navbar {
    padding: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    @include media-breakpoint-up(lg) {
        position: inherit;
    }
}

.navbar-brand {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;

    .logo-home {
        max-width: 181px;
    }

    .logo {
        width: auto;
    }

    /* stylelint-disable scss/media-feature-value-dollar-variable */
    @media (min-width: 1200px) {
        .logo-alt {
            display: none;
        }
    }

    @media (max-width: 1199.98px) {
        .logo {
            display: none;
        }
    }

    @include media-breakpoint-down(md) {
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

@include media-breakpoint-up(lg) {
    .navbar-expand-lg {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;

        .navbar-nav {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;

            .nav-link {
                padding-right: 0;
                padding-left: 0;
            }
        }
    }

    .level-1 {
        > .nav-item {
            border-bottom: 4px solid transparent;

            &:hover {
                border-bottom: 4px solid var(--skin-primary-color-1);
            }
        }
    }
}
