.store-locator-container {
    .btn-storelocator-search {
        background-color: $light-gray;
        border-color: #{var(--skin-primary-color-1)};
        border-radius: 0;
        color: #{var(--skin-primary-color-1)};

        &:hover {
            background-color: #{var(--skin-primary-color-1)};
            color: $light-gray;
        }
    }

    .detect-location {
        margin-bottom: 1em;
    }

    .store-name {
        font-weight: 600;
    }

    .store-details {
        display: block;
        @include media-breakpoint-up(md) {
            margin-left: 3.125em;
        }
    }

    .striped {
        > div:nth-of-type(odd) {
            background-color: $table-accent-bg;
        }
    }

    .search-form {
        width: 100%;
        margin-bottom: 4rem;
    }

    .select-store {
        @include media-breakpoint-up(sm) {
            margin-top: -3rem;
        }
    }

    .custom-radio {
        .store-details {
            margin-left: 1.125em;
        }
    }

    .store-locator-no-results {
        display: none;
    }

    .results {
        @include media-breakpoint-up(md) {
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 21.875em;
        }
    }
}
