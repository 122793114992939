@import "~base/components/header";
@import "./menu";

@mixin absolute-minicart-position() {
    .navbar-inner {
        padding-right: 65px !important;
    }

    .global-minicart-wrapper {
        position: absolute;
        z-index: 4;
        top: 53px;
        right: 0;
    }
}

@mixin reset-minicart-position() {
    .navbar-inner {
        padding-right: 1rem!important;
    }

    .global-minicart-wrapper {
        position: static;

        .minicart-link i,
        .minicart-quantity {
            color: var(--color-white);
        }
    }
}

body:not(.minify-header) {
    .site-header {
        @include media-breakpoint-up(lg) {
            @include reset-minicart-position;
        }
    }
}

.site-header {
    position: fixed;
    z-index: 11;
    width: 100%;

    @include absolute-minicart-position;

    @include media-breakpoint-up(lg) {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.5, 0.25, 0, 1);
        transition: -webkit-transform 0.2s cubic-bezier(0.5, 0.25, 0, 1);
        transition: transform 0.2s cubic-bezier(0.5, 0.25, 0, 1);
        transition: transform 0.2s cubic-bezier(0.5, 0.25, 0, 1), -webkit-transform 0.2s cubic-bezier(0.5, 0.25, 0, 1);
    }

    .preheader {
        background-color: var(--skin-header-bg-color);
        height: 40px;

        a:not(.btn),
        .country-selector,
        .minicart-total {
            text-transform: uppercase;
            color: var(--skin-banner-text-color-1);

            &.checkout-btn-white {
                color: var(--color-link-color);
            }

            @include media-breakpoint-up(lg) {
                font-size: 0.75rem;
            }
        }

        @include media-breakpoint-up(lg) {
            .minicart-total {
                background-color: var(--skin-primary-color-1);
            }
        }

        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }
    }

    .wishlistCount {
        font-size: .8125rem;
        margin: -4px 0 0 6px;
    }

    .promo-banner {
        color: var(--skin-banner-text-color-1);
    }

    .main-menu {
        @include media-breakpoint-up(lg) {
            -webkit-transition: height 0.2s cubic-bezier(0.5, 0.25, 0, 1);
            transition: height 0.2s cubic-bezier(0.5, 0.25, 0, 1);
        }
    }

    .right-nav-minified {
        display: none;
    }

    .mobile-right-nav {
        .mobile-search_btn {
            padding: 1rem;
        }

        .minicart-total {
            padding-top: 0.75rem;
        }

        .wishlist {
            font-size: rem(14px);
        }
    }

    @include media-breakpoint-up(lg) {
        .navbar {
            height: 100%;
        }
    }
}

.header-container {
    width: 100%;
    position: relative;
}

// padding calulate from JS, this is backup if JS not loaded
#maincontent {
    padding-top: 110px;
}

.mobile-right-nav {
    i {
        color: var(--skin-primary-color-1);

        @include media-breakpoint-up(lg) {
            color: var(--skin-main-text-color-2);
        }
    }
}

.minify-header {
    .site-header {

        @include media-breakpoint-up(lg) {
        // hides the preheader in desktop 
        transform: translateY(-40px);

            .right-nav-minified {
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;

                .user-message {
                    display: none;
                }

                .registered-user-message {
                    color: var(--skin-menu-color-1);
                    text-transform: uppercase;
                    font-weight: 600;
                }

                .minicart-total,
                .minicart-quantity,
                i {
                    background: transparent;
                    color: var(--skin-primary-color-1);
                }

                .minicart-total {
                    width: 2.75rem;
                }
            }
        }

        .preheader {
            .minicart-total,
            .minicart-quantity {
                background: transparent;
                color: var(--skin-primary-color-1);
            }

            .minicart-link {
                font-size: 1rem;

                i {
                    color: var(--skin-primary-color-1);
                }
            }
        }

        @include media-breakpoint-up(lg) {
            .minicart .popover {
                margin-top: 40px;
            }
        }

        .global-minicart-wrapper {
            @include media-breakpoint-only(lg) { 
                top: 53px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .navbar-brand {
            .logo {
                display: none;
            }

            .logo-alt {
                display: block;
            }
        }

        .main-menu {
            height: auto;
        }

        .main-nav {
            border: 0;
        }

        .site-search {
            margin-right: 1rem;
        }
    }
}

.user {
    .dropdown-menu {
        min-width: 9rem;
        margin: 0px;

        .nav {
            flex-direction: column;
            li {
                a {
                    color: var(--skin-menu-color-1);
                }
            }
        }
    }
}

.breadcrumb {
    .breadcrumb-item {
        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.navbar-brand {
    @include media-breakpoint-down(sm) {
        margin-right: 0;
    }

    .logo-home {

        @include media-breakpoint-down(md) {
            margin-left: 10%;
        }

        @include media-breakpoint-down(xs) {
            margin-left: 25%;
        }
    }
}

.dropdown-menu .container {
    align-items: start!important;
    .megamenu-inner-block {
        order: 2
    }
}

.menu-images-inner-block {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 0.5rem;

    .image-src-block {
        display: block;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 10.938rem;
        width: 10.938rem;
        outline: 4px solid transparent;
        position: relative;
        margin-bottom: 0.938rem;
        @include media-breakpoint-up(md) {
            height: 14.375rem;
            width: 14.375rem;
        }
        span {
            position: absolute;
            bottom: -1.875rem;
            font-size: 0.688rem;
            font-style: italic;
            letter-spacing: 0.26px;
            width: 100%;
            text-align: center;
            margin-top: 0.75rem;
            line-height: 1.4;
        }

        &:hover {
            outline: 4px solid #2abdbb;
        }
    }

    .image-subtext-block {
        font-size: 0.688rem;
        font-style: italic;
        letter-spacing: 0.26px;
        color: #d8d8d8;
        width: 100%;
        text-align: center;
        margin-top: 0.75rem;
        line-height: 1.4;
    }
}

