@import "../utilities/aspectRatio";

@each $breakpoint in map-keys($grid-breakpoints) {
   @if $breakpoint == "xs" {
      .ar-21x9 {
            @include aspect-ratio(21, 9);
      }

      .ar-9x21 {
         @include aspect-ratio(9, 21);
      }

      .ar-16x9 {
            @include aspect-ratio(16, 9);
      }

      .ar-9x16 {
         @include aspect-ratio(9, 16);
      }

      .ar-4x3 {
         @include aspect-ratio(4, 3);
      }

      .ar-3x4 {
         @include aspect-ratio(3, 4);
      }

      .ar-2x3 {
         @include aspect-ratio(2, 3);
      }

      .ar-3x2 {
         @include aspect-ratio(3, 2);
      }

      .ar-1x1 {
         @include aspect-ratio(1, 1);
      }
   } @else {
      @include media-breakpoint-up($breakpoint) {
         .ar-#{$breakpoint}-21x9 {
               @include aspect-ratio(21, 9);
         }

         .ar-#{$breakpoint}-9x21 {
            @include aspect-ratio(9, 21);
         }

         .ar-#{$breakpoint}-16x9 {
               @include aspect-ratio(16, 9);
         }

         .ar-#{$breakpoint}-9x16 {
            @include aspect-ratio(9, 16);
         }

         .ar-#{$breakpoint}-4x3 {
            @include aspect-ratio(4, 3);
         }

         .ar-#{$breakpoint}-3x4 {
            @include aspect-ratio(3, 4);
         }

         .ar-#{$breakpoint}-2x3 {
            @include aspect-ratio(2, 3);
         }

         .ar-#{$breakpoint}-3x2 {
            @include aspect-ratio(3, 2);
         }

         .ar-#{$breakpoint}-1x1 {
            @include aspect-ratio(1, 1);
         }
      }
   }
}

@each $breakpoint in map-keys($grid-breakpoints) {
   @if $breakpoint == "xs" {
      .object-fit-contain {
            object-fit: contain !important;
      }
      .object-fit-cover {
         object-fit: cover !important;
      }
   } @else {
      @include media-breakpoint-up($breakpoint) {
         .object-fit-#{$breakpoint}-contain {
            object-fit: contain !important;
         }
         .object-fit-#{$breakpoint}-cover {
            object-fit: cover !important;
         }
      }
   }
}
