@import "../variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "~base/skin/skin";
@import "../utilities/globalmixins";

.embeddedServiceHelpButton {
    z-index: 10 !important;

    .helpButton {

        @include media-breakpoint-only(xs) {
            bottom: 70px !important
        }

        .uiButton {
            background-color: var(--skin-btn-background-color) !important;
            font-family: var(--skin-header-font);
            min-width: 7em;

            &:focus {
                outline: 1px solid var(--skin-btn-border-color) !important;
                min-width: 7em;
            }
        }

        .helpButtonEnabled:hover::before, 
        .helpButton .helpButtonEnabled:focus::before {
            opacity: 1 !important;
            background-color: var(--skin-btn-background-color) !important;
        }

    }
}

.embeddedServiceSidebarMinimizedDefaultUI .minimizedText,
.embeddedServiceSidebarForm .fieldList .uiInputText input,
.embeddedServiceSidebarFormField .uiInput .uiLabel-left,
.embeddedServiceSidebarFormField .slds-style-select,
.embeddedServiceSidebarFormField .slds-style-inputtext,
.embeddedServiceSidebarFormField .uiInputSelect>select.select,
.embeddedServiceSidebarFormField .uiInputDefaultError .form-element__help,
.embeddedServiceLiveAgentStateChatInputFooter .chasitorText,
.embeddedServiceSidebarButton,
.embeddedServiceSidebar.modalContainer {
    font-family: var(--skin-header-font) !important;
}

.quantity-form-wishlist {
    padding-right: 50px;
}

.add-to-cart-wishList {
    padding-right: 50px;
}