.fa-search {
    right: unset;
}

.do-you-mean-container {
    justify-content: space-between;
    border-bottom: 1px solid var(--color-hr-border-color);
    margin: 0;
    padding: 0 10px;
}

.single-product-container {
    img {
        width: 80px;
        height: 80px;
    }
}

.cat-product-container {
    margin: 0;
    flex-direction: row-reverse;

    @include media-breakpoint-down(md) {
        flex-direction: column-reverse;
    }
}

.category-container,
.product-container {
    .header {
        color: var(--skin-main-text-color-1);
    }
}

.prd-main,
.prod-image {
    padding: 0;
}

.suggestions {
    width: 44rem;
    overflow-y: auto;

    .container {
        display: block;
        padding: 0;
    }

    .header {
        &:not(:first-child) {
            border: none;
        }
    }

     @include media-breakpoint-up(lg) {
        .category-container {
            border-right: 1px solid var(--color-hr-border-color);
        }
    }

    a {
        color: var(--skin-primary-color-2);
    }

    @include media-breakpoint-down(md) {
        position: absolute;
        top: -1px;
        width: 100%;
        max-width: 44rem;
    }

    @include media-breakpoint-down(sm) {
        max-height: 60vh;
        min-height: unset;
    }
}

.site-search {
    height: auto;

    input {
        padding-right: 2rem;
        padding-left: 30px;
        font-size: 14px;
        color: var(--skin-header-search-text-color);
        height: 35px;
        line-height: 35px;
        border-radius: unset;
        background-color: var(--color-white);
    }

    .fa-search {
        left: 6px;
        right: unset;
        &:focus {
            outline: 2px dotted;
        }
    }

    @include media-breakpoint-up(sm) {
        margin-right: 0;
    }

    @include media-breakpoint-up(lg) {
        margin-right: 0.5em;
    }

    .reset-button {
        right: 20px;

        @include media-breakpoint-down(sm) {
            display: block !important;
        }
    }

}

@include media-breakpoint-down(md) {
    .site-search {
        width: 100%;
    }

    .site-search-active {
        .search {
            transform: translateY(93px);
            margin: 17px 0 0;
        }
    }

    .search {
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        -webkit-transform: translateY(0);
        transform: translateY(0);
        transition: transform 0.5s cubic-bezier(0.5, 0.25, 0, 1);
        width: 100vw;
        z-index: -1;
    }
}
