.customQty,
.line-item-quantity {
    display: table-caption;

    .customqty-stepper {
        display: flex;
        background: $grey11;
        height: 2.5rem;
        max-height: 50px;

        input {
            width: 3.125rem;
            height: 100%;
            text-align: center;
            border: 1px solid $grey11;
            border-radius: 0;
            outline: none;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            &:focus {
                outline: none;
            }
            &:disabled {
                background: $white;
            }
        }
        .qty-minus,
        .qty-plus {
            i {
                color: $white !important;
                font-size: 0.75rem;  
            }

            width: 3.125rem;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}
.line-item-quantity {
    .quantity {
        min-width: 2.5em;
    }

    .customqty-stepper {
        height: 2rem;
    }
}
