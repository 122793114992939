@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "~base/miniCart";

.popover {
    color: var(--color-link-color);

    &.show {
        @include media-breakpoint-down(sm) {
            width: 90%;
            min-width: initial;
        }
    }

    .line-item-name {
        width: 92%;
    }
}

.minicart-container {
    @include media-breakpoint-down(sm) {
        position: absolute;
        right: 15px;
        top: 20px;
        z-index: 10;
    }
}

.minicart-icon {
    @include media-breakpoint-down(sm) {
        font-size: 24px;
        color: #{var(--skin-primary-color-1)};
    }
}

.minicart-total {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 2.5rem;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 3.75rem;
}

.minicart-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    i {
        color: var(--skin-primary-color-1);

        @include media-breakpoint-up(lg) {
            color: var(--skin-main-text-color-2);
        }
    }
}

.minicart {
    margin: 0;

    .cart {
        height: 100vh;
    }

    .minicart-quantity {
        position: static;
        background-color: transparent;
        color: var(--skin-primary-color-1);
    }

    .product-summary {
        height: 100vh;
        max-height: 73vh;
    }

    .btn-close {
        border: none;
        background: none;
        margin-top: -5px;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    .popover {
        position: fixed;
        right: -1px;
        display: block;
        top: -1px;
        transform: translateX(100%);
        z-index: 1001;
        @include transition(transform, 426ms);

        &.show {
            transform: translateX(0);
        }
    }
}

.product-lineitem-container {
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
}
