$breakpoint-name: 'md';
$breakpoint-name: 'md' !default;
$breakpoint-index: index(map-keys($grid-breakpoints), $breakpoint-name);
$prev-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index - 1);
$next-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index + 1);

$slide-out-animation: left 0.5s cubic-bezier(0, 1, 0.5, 1);

@mixin caret-left() {
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-left: 0.3 solid transparent;
    width: 0;
    height: 0;
    display: inline-block;
    margin-bottom: 0.125em;
}

@mixin caret-right() {
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
    position: absolute;
    right: 0.3em;
    margin-top: 0.55em;
}

@each $size in map-keys($grid-breakpoints) {

    @include media-breakpoint-down($size) {
        .menu-toggleable-left.navbar-toggleable-#{$size} {
            position: fixed;
            left: -100%;
            top: 0;
            bottom: 0;
            transition: $slide-out-animation;
            display: block;
            max-width: 100%;

            &.in {
                min-width: 50%;
                left: 0;
            }
        }
    }
}

.navbar.bg-inverse {
    background-color: transparent !important;
    padding: 0;

    @include media-breakpoint-up($next-breakpoint) {
        .navbar-nav .nav-item + .nav-item {
            margin-left: 0;
        }

        .navbar-nav .nav-link {
            padding: 0.8rem;
            white-space: nowrap;
        }
    }
}

.navbar-expand-md .navbar-nav.nav-center {
    justify-content: center;
}

.navbar-expand-md .navbar-nav.nav-spaced {
    justify-content: space-evenly;
}

.navbar-expand-md .navbar-nav.nav-right {
    justify-content: end;
}

.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
    background-color: $white;
    color: $menu-link;
}

@include media-breakpoint-up($next-breakpoint) {
    .nav-item > .nav-link {
        color: $white;
    }
}

.main-menu.menu-toggleable-left {

    @include media-breakpoint-down($breakpoint-name) {
        background-color: $white;
        z-index: 4;
        overflow-y: auto;
    }
}

.menu-toggleable-left {
    .close-menu {
        padding: 15px;
        background-color: $close-menu-bg;
        border-bottom: 1px solid $grey3;
        flex: 0 0 100%;

        @include media-breakpoint-up($next-breakpoint) {
            display: none;
        }
    }

    .menu-group {
        flex: 0 0 100%;
    }

    li > .close-menu {
        margin-right: 0;
        margin-top: -0.6rem;
        margin-left: 0;
    }

    @include media-breakpoint-down($breakpoint-name) {
        .bg-inverse {
            background-color: white !important;
            color: $grey7;
        }
    }

    &.in {

        @include media-breakpoint-down($prev-breakpoint) {
            right: 0;
            margin-right: 1.25em; /* 20/16 */
        }

        @include media-breakpoint-down($breakpoint-name) {
            .nav-item + .nav-item {
                border-top: 1px solid $grey2;
            }

            .dropdown {
                display: block;
                position: static;
            }

            .dropdown-toggle {
                padding-left: 1rem;

                &::after {
                    @include caret-right();
                }
            }

            .nav-item .nav-link {
                padding-left: 1rem;
                text-transform: uppercase;
                font-weight: 600;
            }

            .show > .dropdown-menu {
                left: 0;
            }

            .dropdown-menu {
                position: absolute;
                left: -100%;
                top: 0;
                width: 100%;
                height: 100%;
                border: 0 none;
                transition: $slide-out-animation;
                display: block;
            }
        }
    }
}
