@import "../variables";

// PDP question section
.pr-qa-display-desktop {
    padding: 0 !important;
}

.pr-qa-display-headline {
    margin: 0 !important;
}

.pr-review-display {
    margin: 1rem 0 !important;
}

// PDP top section
.p-w-r {
    color: var(--skin-main-text-color-1) !important;
    font-family: var(--skin-body-font) !important;

    .pr-snippet-qa.pr-snippet-qa-empty {
        .pr-ask-question-cta {
            font-family: var(--skin-body-font) !important;
        }
    }

    .pr-snippet-stars-reco-inline {
        .pr-snippet-read-and-write {
            a,
            span {
                font-size: 1rem !important;
            }
        }

        &.pr-snippet-minimal {
            .pr-snippet-read-and-write {
                margin-top: 0 !important;
            }
        }
    }
}

// star stylings
.p-w-r {
    .pr-star-v4 {
        background: none !important;
        padding: 0 !important;
        height: 16px !important;
        width: 16px !important;

        &::before {
            position: absolute;
            font-family: "FontAwesome";
            content: '\f005';
            font-size: 1rem;
            color: var(--skin-review-stars-color);
            z-index: 1;
            overflow: hidden;
        }
    }

    .pr-star-v4-75-filled:before {
        width: 11px;
    }

    .pr-star-v4-50-filled:before {
        width: 8px;
    }

    .pr-star-v4-25-filled:before {
        width: 5px;
    }

    .pr-star-v4-0-filled:before {
        width: 0;
    }

    .pr-star-v4-0-filled,
    .pr-star-v4-25-filled,
    .pr-star-v4-50-filled,
    .pr-star-v4-75-filled {
        &::after {
            position: absolute;
            font-family: "FontAwesome";
            content: '\f005';
            color: $grey3;
            font-size: 1rem;
        }
    }

    .pr-review-snapshot {
        .pr-ratings-histogram {
            .pr-ratings-histogram-barValue {
                background: var(--color-primary) !important;
            }
        }

        .pr-snippet {
            .pr-snippet-stars-png {
                display: flex;
                align-items: center;
            }
        }
    }

    .pr-media-modal {
        .button__close {
           top: 50px !important;
        }
    }

    .pr-snippet-stars-container {
        display: inline-block !important;
    }

    .pr-snippet-read-and-write {
        font-size: 1rem !important;
        font-family: var(--skin-body-font);
    }

    .pr-snippet-rating-decimal {
        font-size: 1rem !important;
        font-family: var(--skin-body-font);
        border: 0 !important;
        color: var(--skin-main-text-color-1) !important;
    }
}

.pr-custom-write-review-form {
    max-width: 31.25rem;
    width: 100%;
    padding: 20px;
    margin: auto;
}

.pr-custom-review-display {
    .p-w-r {
        .pr-review-snapshot {
            .pr-review-snapshot-simple {
                .pr-review-snapshot-snippets {
                    .pr-snippet-read-and-write {
                        a {
                            color: $white !important;
                        }
                    }
                }
            }
        }

        .pr-review-display {
            .pr-snippet-rating-decimal {
                height: auto;
                line-height: 12px;
            }

            .pr-rd-review-headline {
                line-height: 16px;
            }
        }
    }
}