.site-search {
    input {
        background-color: var(--skin-background-color-2);
    }

    @include media-breakpoint-up(lg) {
        form {
            margin: 0;
        }
    }
}

@include media-breakpoint-down(md) {
    .site-search-active {
        .search {
            margin: 0;
            transform: translateY(88px);
        }

        &.minify-header {
            .search {
                transform: translateY(48px);
            }
        }

        &.mobile-promo-disabled {
            .search {
                transform: translateY(48px);
            }
        }
    }
}

.single-product-container {
    img {
        max-width: 100px;
        width: 100%;
        height: auto;
    }
}

