@import "~providiobase/variables";

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 1.75),
    6: ($spacer * 2),
    7: ($spacer * 2.25),
    8: ($spacer * 2.5),
    9: ($spacer * 2.75),
    10: ($spacer * 3),
    11: ($spacer * 3.25),
    12: ($spacer * 3.5),
    13: ($spacer * 3.75),
    14: ($spacer * 4),
    15: ($spacer * 4.25),
    16: ($spacer * 4.5),
    17: ($spacer * 4.75),
    18: ($spacer * 5),
    19: ($spacer * 5.25),
    20: ($spacer * 5.5),
    21: ($spacer * 5.75),
    22: ($spacer * 6),
    23: ($spacer * 6.25),
    24: ($spacer * 6.5),
    25: ($spacer * 6.75)
  ),
  $spacers
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1800px
);

$grey9: #f1f1f1;
$grey10: #f5f5f5;
$grey11: #707070;
$grey12: #F3F3F3;
$grey13: #AFAFAF;
$grey14: #EAEAEA;
$grey15: #B5B5B5;
$grey16: #D2D2D2;
$grey17: #A3A3A3;
$grey18: #636363;
$grey19: #E4E4E4;
$grey20: #AEAEAE;

$sea-green: #2CA7B0;
$dazzled-blue: #285D89;
$dark-red: #c82032;
$Japanese-Carmine: #A02C39
