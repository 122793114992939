@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700&display=swap');
$light-blue: #6dccee;
$light-blue2: #56c4eb;
$fb-blue: #3b5998;
$twitter-blue: #1da1f2;
$pin-red: #e60023;
$grey9:#e6e6e6;
$grey11: #707070;
$athens-gray: #e2e6ea;
$cynical-black: #171717;
$link-hover-color: #f90;
$smalt: #003399;
$steam: #dddddd;
$squant: #666666;
$menu-color: #333333;
$love-goddess: #cc0f08;
$aria: #e3e3e3;
$cobalt-stone: #0067b1;
$fortress-grey: #b8b8b7;
$dropdown-hover: #1e90ff;
$black-tie: #474747;
$snowbank: #e9e9e9;


$grid-breakpoints: (
    xs: 0,
    sm: 544px,
    md: 769px,
    lg: 992px,
    xl: 1200px
) !default;

$container-max-widths: (
	md: 720px,
    xl: 1440px
) !default;

// Font sizes
$base-font-size: 1rem !default;
$xl-font-size: $base-font-size * 1.67 !default;
$lg-font-size: $base-font-size * 1.5 !default;

$heading-1-font-size: $base-font-size * 2 !default;
$heading-2-font-size: $base-font-size * 1.75 !default;
$heading-3-font-size: $base-font-size * 1.5 !default;
$heading-4-font-size: $base-font-size * 1.25 !default;
$heading-5-font-size: $base-font-size !default;

$base-font-family: var(--skin-body-font);
$dosis-sans-serif: var(--font-family-dosis-sans-serif);

@import "~base/variables";
