@import "~base/components/quickView";
@import "../product/productSlick";

.quick-view-dialog,
.choose-bonus-product-dialog {
    max-width: 50em;

    .modal-header {
        background-color: transparent;
        border: 0;
        font-weight: 600;
    }

    .modal-body {
        max-height: 43.125em;
    }

    .promotions {
        color: var(--color-primary);
    
        div.collapsible-promotions {
            
            &.callout {
                color: var(--skin--color-promotional-text);
                line-height: normal;
                font-size: 1rem;
                padding: 0.6rem 0;
            }

            &:focus {
                box-shadow: none;
            }
        }
    }

    .prices-add-to-cart-actions,
    .attribute.quantity {
        padding: 0;
    }

    .set-description {
        h2 {
            font-size: 1.75rem;
            text-transform: uppercase;
            font-weight: 500;
        }
    }

    .set-item {
        .product-name {
            font-size: 1.5rem;
            font-weight: 500;
        }
    }
}

.add-to-cart-global,
.update-cart-product-global {
    width: 100%;
}
