@mixin absolute-minicart-position() {
    .navbar-inner {
        padding-right: 65px !important;
    }

    .global-minicart-wrapper {
        position: absolute;
        z-index: 4;
        top: 46px;
        right: 0;

        .minicart-link i,
        .minicart-quantity {
            color: var(--color-black);
        }
    }
}

@mixin reset-minicart-position() {
    .navbar-inner {
        padding-right: 1rem!important;
    }

    .global-minicart-wrapper {
        position: static;

        .minicart-link i,
        .minicart-quantity {
            color: var(--color-white);
        }
    }
}

body:not(.minify-header) {
    .site-header {
        @include media-breakpoint-up(lg) {
            @include reset-minicart-position;
        }
    }
}

.site-header {

    @include absolute-minicart-position;

    @include media-breakpoint-down(md) {
        .pull-left {
            .logo-home {
                img {
                    width: 110px;
                }
            }
        }
    }

    .main-menu {
        + .pull-right {

            @include media-breakpoint-down(md) {
                right: 91px;
            }

            .mobile-search_btn {
                padding: 1rem 0;
            }
        }

        @include media-breakpoint-up(lg) {
            padding-bottom: 0.7px;
        }
    }

    .preheader {
        color: var(--color-white);
        height: auto;
        
        .minicart-total {
            background: transparent;

            @include media-breakpoint-up(lg) {
                color: var(--color-white);
            }
        }

        @include media-breakpoint-up(lg) {
            height: 40px;
        }
    }

    .mobile-right-nav  {

        .mobile-search_btn {
            line-height: 16px;
        }
    }
}

.minify-header {

    @include media-breakpoint-up(lg) {
        .navbar-brand {
            .logo {
                display: block;
            }

            .logo-alt {
                display: none;
            }
        }
    }

    @include media-breakpoint-only(lg) { 
        .navbar-brand {
            .logo {
                display: none;
            }

            .logo-alt {
                display: block;
            }
        }
    }

    .site-header {
        // hides the preheader in desktop 
        .mobile-promo-banner {
            display: none;
        }

        .global-minicart-wrapper {
            @include media-breakpoint-down(lg) {
                top: 5px;
            }

            @include media-breakpoint-only(lg) { 
                top: 46px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .main-nav {
            margin-right: initial;
        }

        a.minicart-link {
            font-size: 1rem !important;
        }
    } 
}

.mobile-promo-disabled {
    .site-header {
        .global-minicart-wrapper {
            @include media-breakpoint-down(lg) {
                top: 5px;
            }
        }
    }

    &.minify-header {
        .site-header {
            .global-minicart-wrapper {
                @include media-breakpoint-down(lg) {
                    top: 5px;
                }

                @include media-breakpoint-only(lg) { 
                    top: 46px;
                }
            }
        }
    }
}

.mobile-promo-banner {
    background-color: var(--skin-header-bg-color);

    > div {
        @include media-breakpoint-down(md) {
            padding: 0 3rem;
            box-sizing: border-box;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            transition: transform .2s cubic-bezier(.5,.25,0,1);

            a {
                color: var(--color-white);
                font-size: .75rem;
                text-transform: uppercase;
            }
        }
    }
}

.navbar-brand {
    .logo-home {

        @include media-breakpoint-down(md) {
            margin-left: 10%;
        }

        @include media-breakpoint-down(xs) {
            margin-left: 35%;
        }
    }
}
