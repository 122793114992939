
@import "~providiobase/components/common";

#shop-plans,
#how-it-works,
#diet-menu,
#success-stories {
    &.container,
    &.container-fluid,
    .container,
    .container-fluid {
        padding: 0 17px;
        @include media-breakpoint-up(sm) {
            max-width: 1920px;
        }
        @include media-breakpoint-up(xl) {
            padding: 0 60px;
        }
    }

    .editorialRichText-component-container {
        p {
            margin: 0;
        }
        h1, h2, h3, h4, h5, h6, p {
            line-height: 1.3637;
            font-size: inherit;
            font-weight: inherit;
            font-family: inherit;
            margin: 0;
        }
    }

    .experience-component {
        > div {
            display: block;
            width: 100%;
        }
    }
}

.cursor-default {
    cursor: default !important;
}

.container {
    @include media-breakpoint-up(lg) {
        max-width: 100%;
    }

    @include media-breakpoint-up(xl) {
        max-width: 1440px;
    }
}
.prop65 {
    a {
        color: var(--color-dazzled-blue) !important;

        &:hover {
            text-decoration: underline;
        }
    }
}

.line-item-option-toggle {
    cursor: pointer;

    &[data-toggle="collapse"] {
        &::after {
            content: '+';
            font-size: 1rem;
            margin-left: 0.25rem;
            color: #228087;
        }
        &:not(.collapsed)::after {
            content: '-';
        }
    }
}

// CSS changes to DD from plugin cartridge
.wl-social-sharing {
    [data-share="facebook"],
    [data-share="twitter"],
    [data-share="pinterest"] { 
        display: none;
    }

    .share-icons-copy-link-btn {
        border: 0;
        background: none;
    }
}

.wishlist-hide { 
    display: none;
}

#homepage {
    .product-tile {
        &:hover {
            .image-container {
                .quickview,
                .add-to-cart {
                    display: none;
                }
            }
        }
    }
}

.remove-bt-paypalpayment {
    border: none;
    background: transparent;
	color: #0070d2;
	text-decoration: underline;
}

// card loop - prov update 
.card {
    .card-body {
        .card {
            border: 0;

            .card-header,
            .card-footer {
                padding-left: 0;
                padding-right: 0;   
                border: 0;

                a:not(.btn) {
                    color: var(--skin-btn-text-color) !important;
                    text-decoration: none;
                }
            }
        }
    }

    .btn-outline-primary {
        text-decoration: none !important;
        @include border-radius(0.255rem);
    }

    .card-link {
        background-color: var(--skin-btn-background-color);
        border-color: var(--skin-btn-border-color);
        text-transform: capitalize;
        text-decoration: none !important;
        font-size: .75rem;
        color: var(--skin-btn-text-color) !important;
        padding: 0.375rem 0.75rem;
        display: inline;
        @include border-radius(0.255rem);
    }

}

.product-discount {
    color: var(--color-japanese-carmine);
}

