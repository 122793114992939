@import "~base/storeLocator";
@import "./variables";
@import "./utilities/globalmixins";

.select-store-messages {
    transform: translate(-50%, -50%);
    position: fixed;
    top: 28%;
    left: 50%;
    z-index: 99;
}

.store-locator-container {
    .card {
        border: none;
        margin-bottom: 0;

        &.search-form {
            label {
                margin-bottom: 0;
                padding-left: 0;
            }

            input {
                padding-left: 0;

                &:focus {
                    box-shadow: inset 0 -1px 0 $grey9;
                }
            }
        }

        .card-body {
            padding: 0;
            font-size: rem(13px);

            .form-group {
                margin-bottom: rem(10px);
            }

            .store-locator-no-results {
                padding: rem(30px, 0, 10px);
            }

            .form-check-label {
                width: 100%;

                table {
                    width: 100%;
                }
            }
        }
    }
}

#findInStoreModal {
    height: 100%;
  
    &.modal-left,
    &.modal-full-height {
      .modal-dialog {
        max-width: rem(350px);
        margin: auto;
      }
  
      .modal-content {
        border: unset;
        border-radius: unset;
      }
    }
  
    &.modal-left {
      left: 0;
      right: auto;
  
      .modal-dialog {
        margin-left: 0;
        height: 100%;
        overflow-y: auto;
      }

      &.fade {
        .animation-slide-in-left-modal {
          @include transform(translate(-100%, 0));
        }
      
        &.show {
          .animation-slide-in-left-modal {
            @include transform(none);
          }
        }
      }
    }
  
    &.modal-full-height {
      .modal-content {
        height: 100%;
        overflow-y: inherit;
      }
    }

    .modal-header {
        padding: rem(10px, 0);
        border: none;

        .close {
            position: absolute;
            right: 0;
            top: rem(-13px);
            padding: 0;
            margin: 0;
            font-size: rem(35px);
            z-index: 1;
        }
    }

    .results {
        max-height: inherit;
        overflow: inherit;
        margin-right: 0;

        > .store-contain {
            background-color: transparent;
            border-bottom: rem(1px) solid $grey9;
            padding: rem(15px, 0, 20px);

            &:first-child {
                border-top: rem(1px) solid $grey9;
                margin-top: rem(20px);
            }
        }

        .store-details {
            a {
                color: var(--skin-link-color-1) !important;
            }
        }
    }

    .modal-title {
        font-size: rem(16px);
        font-weight: 600;
        padding-left: rem(15px);
    }

    .selected-storedata {
        background-color: $grey9;
        padding: rem(15px);
        font-size: rem(13px);

        p,
        address {
            margin-bottom: 0;
        }
    }
}

.selected-storedata {
    table {
        width: 100%;
        margin: 5px 0px;
    }
}

#storeDetails {
    .modal-dialog {
        width: rem(350px);

        .modal-content {
            border-radius: 0;
            border: none;
            font-size: rem(14px);
        }
    }
}