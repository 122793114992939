//Modified Product Tiles as per the new UI.

@import "~base/components/productTiles";
@import "../utilities/globalmixins";
@import "productCommon";
@import "quickView";

.product {
    max-width: 18.75em;
    transition: box-shadow 0.2s;

    @include media-breakpoint-up(md) {
        &:hover {
            box-shadow: 0 0 13px 0 var(--color-light-gray2);
            z-index: 3;
        }
    }

    @include media-breakpoint-down(md) {
        min-height: 23.4375em;
        max-width: 14.0625em;
    }

    @include media-breakpoint-down(xs) {
        min-height: 13.4375em;
        max-width: inherit;
    }
}

.product-tile {
    max-width: inherit;

    .tile-body {
        .pdp-link {
            display: flex;
            justify-content: center;

            a {
                display: flex;
                height: auto;
                padding: 5px 0;
                text-align: center;
                align-items: center;

                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                padding: 0;
                /*autoprefixer: off*/
            }
        }
        .plp-promotion {
			overflow: hidden;
			font-size: 12px;
			line-height: 17px;
			color: $cyan;
			margin: 5px 0px;
		}
    }

    .image-container {

        @include media-breakpoint-up(lg) {
            .tile-img-contain {
                min-width: rem(250px);
            }
        }

        .badges {
            position: absolute;
            top: 0.625rem;
            left: 0;
            padding: 0.313rem 0.625rem;

            .product-badge-image {
                width: 1.563rem;
            }

            .product-badge-text {
                border-radius: rem(5px);
                padding: 2px 10px;
                color: $white;
                margin-bottom: 5px;
                font-size: 11px;
                &.new {
                    background-color: #ed7014;
                }
                &.bestseller {
                    background-color: #7b1fa2;
                }
                &.sale {
                    background-color: #f7022a;
                }
                &.newcolor {
                    background-color: #ff1e71;
                }
            }
        }

        .wishlistTile {
            position: absolute;
            top: 0.625rem;
            right: 0.625rem;
        }

        .quickview {
            bottom: 0;
            right: 0;
            opacity: 0;
            text-transform: uppercase;

            @include transition (opacity, 0.2s);
        }

        .button-outline-primary {
            background-color: var(--skin-btn-background-color);
            border-color: var(--skin-btn-border-color);
            color: var(--skin-btn-text-color);
        }

        .button-outline-primary:hover {
            background-color: var(--skin-primary-color-1);
            border-color: var(--color-primary-button-hover);
            color: var(--skin-main-text-color-2);
        }

        .select-button {
            position: absolute;
            bottom: 0;
            left: 0;
            text-transform: uppercase;
            @include transition (opacity, 0.2s);

            @include media-breakpoint-down(sm) {
                width: 100% !important;
            }
        }

        .add-to-cart {
            position: absolute;
            bottom: 0;
            left: 0;
            text-transform: uppercase;
            @include transition (opacity, 0.2s);
            background-color: var(--color-success);
            color: var(--color-white);

            @include media-breakpoint-down(sm) {
                width: 100% !important;
            }
        }
        .plp-size-list {
            position: absolute;
            bottom: 25px;
            left: 0px;
            text-align: center;
            background-color: var(--skin-primary-color-1);
            color: var(--skin-main-text-color-2);
            padding-top: 10px;
            width:100%;
            overflow:auto;
            margin-bottom: 15px;

            ul {
                list-style: none;
                display: flex;
                justify-content: start;
                padding-left: 0px;

                li {
                     margin: 2px;
                     border: 2px solid var(--skin-btn-text-color);
                     border-radius: 50%;
                     min-width:30px;
                     cursor: pointer;

                     a {
                        color: var(--color-white) !important;
                     }
                }

                .selected {
                    background-color: var(--color-success);
                }
                .unselectable {
                    cursor: default;
                    position: relative;
                }
                .unselectable:before {
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 50%;
                    right: 0;
                    border-top: 2px solid;
                    border-color: inherit;
                    transform:rotate(45deg);
                 }
            }
        }
    }

    .swatches {
        a {
            .swatch-selected {
                border: 2px solid var(--color-success);
            }
        }
    }

    &:hover {
        .image-container {
            .quickview {
                opacity: 1;
            }
            .add-to-cart {
                opacity: 1;

            }
        }
    }

    a {
        &.select-button,
        &.js-pt-add-to-cart,
        &.quickview {
            height: 32px;
        }
    }
}

.product-quickview  {
    .prices {
        padding-bottom: 0;
    }

    div.availability {
        margin-top: 0.8rem;
    }
}

.qv-ratings {
    .ratings {
        margin: 0;
    }
}
