@import "../variables";

:root {
    /** ==================================================================== **/

    /** Create Custom CSS Variables from "sfra bootstrap override" variables **/

    /** ==================================================================== **/
    --color-white: #{$white};
    --color-black: #{$black};
    --color-blue: #{$blue};
    --color-green: #{$green};
    --color-primary: #097fb3;
    --color-main-text-color-1: #393939;
    --color-main-text-color-2: var(--color-white);
    --color-red: #{$red};
    --color-success: var(--color-green);
    --color-danger: var(--color-red);
    --color-light-blue: #{$light-blue};
    --color-light-blue-2: #{$light-blue2};
    --color-btn-hover: #{#07658f};
    --color-promotional-text: #88c290;
    --color-gray: #e6e6e6;
    --color-light-gray2: #d5d5d5;
    --color-error-red: #df5b5b;
    //--color-blue-light: #{$blue-light};
    //--color-blue-dark: var(--color-primary);

    // Consolidated values
    --color-grey1: #{$grey1};
    --color-grey2: #{$grey2};
    --color-grey3: #{$grey3};
    --color-grey4: #{$grey4};
    --color-grey5: #{$grey5};
    --color-grey6: #{$grey6};
    --color-grey7: #{$grey7};
    --color-grey8: #{$grey8};
    --color-grey-transparent-1: rgba(0, 0, 0, 0.65);
    --color-grey-transparent-2: rgba(121, 24, 24, 0.25);
    --color-light-gray: var(--color-grey1);
    --color-slightly-darker-gray: var(--color-grey4);
    --color-darker-gray: var(--color-grey7);
    --color-horizontal-rule-grey: var(--color-grey3);
    --color-product-number-grey: var(--color-grey3);
    --color-horizontal-border-grey: var(--color-grey4);
    --color-menu-link: var(--color-grey6);
    --color-close-menu-bg: var(--color-grey2);
    --color-link-color: var(--color-dark-gray);
    --color-hr-border-color: var(--color-grey3);
    --color-primary-button: var(--color-primary);
    --color-primary-button-hover: var(--color-btn-hover);

    // Fonts family, sizes
    --font-family-source-sans-pro: 'Source Sans Pro', 'sans-serif';
    --font-family-dosis-sans-serif: 'Dosis', sans-serif;

    // Text decorators
    --text-decoration-style: none;

    /** ==================================================================== **/

    /** Define skin named variables here                                    **/

    /** ==================================================================== **/
    --skin-background-color-1: var(--color-white);
    --skin-background-color-2: var(--color-light-blue);
    --skin-background-color-3: var(--color-light-blue-2);
    --skin-background-color-4: var(--color-light-gray);
    --skin-border-color-1: var(--color-white);
    --skin-main-text-color-1: var(--color-main-text-color-1);
    --skin-main-text-color-2: var(--color-main-text-color-2);
    --skin--color-promotional-text: var(--color-promotional-text);
    --skin-menu-color-1: var(--color-main-text-color-1);
    --skin-menu-color-1-invert: var(--color-white);
    --skin-menu-color-2: #222;
    --skin-link-color-1: var(--color-main-text-color-1);
    --skin-link-color-inverse: var(--color-white);
    --skin-link-color-2: var(--color-primary);
    --skin-primary-color-1: var(--color-primary);
    --skin-primary-color-2: var(--color-primary);
    --skin-primary-color-invert-1: var(--color-white);
    --skin-selectbox-background-color-1: var(--color-white);
    --skin-selectbox-text-color-1: var(--skin-main-text-color-1);
    --skin-banner-background-color-1: var(--color-white);
    --skin-banner-background-color-2: #222;
    --skin-banner-text-color-1: var(--color-white);
    --skin-heading-color-1: var(--color-primary);
    --skin-heading-color-1-invert: var(--color-white);
    --skin-price-1: var(--color-gray7);
    --skin-body-font: var(--font-family-source-sans-pro);
    --skin-header-font: var(--font-family-source-sans-pro);
    --skin-headers-font: var(--font-family-source-sans-pro);
    --skin-header-bg-color: var(--color-light-blue);
    --skin-header-search-text-color: var(--color-grey3);
    --skin-link-decoration-style: var (--text-decoration-style);
    --skin-btn-text-color: var(--color-white);
    --skin-btn-background-color: var(--color-primary-button);
    --skin-btn-background-hover: var(--color-primary-button-hover);
    --skin-btn-border-color: var(--color-primary-button);
    --skin-input-border-color: var(--color-gray);
    --skin-input-text-color: var(--color-main-text-color-1);
    --skin-review-stars-color: var(--color-primary);
}
