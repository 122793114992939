@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?jn5o9d');
    src:  url('../fonts/icomoon.eot?jn5o9d#iefix') format('embedded-opentype'),
      url('../fonts/icomoon.ttf?jn5o9d') format('truetype'),
      url('../fonts/icomoon.woff?jn5o9d') format('woff'),
      url('../fonts/icomoon.svg?jn5o9d#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-apps:before {
    content: "\e900";
  }
  .icon-like:before {
    content: "\e901";
  }
  .icon-play:before {
    content: "\e902";
    color: #fff;
  }
  .icon-setup_assistant_guide:before {
    content: "\e903";
  }
  .icon-shopping_bag:before {
    content: "\e904";
  }
  .icon-tick-mark:before {
    content: "\e905";
    color: #00ba2b;
  }

  .icon-tick-mark-rounded {
    position: relative;
    &:before {
      content: "\e906";
      color: rgb(25, 188, 90);
    }
    &:after {
      content: "\e907";
      color: rgb(255, 255, 255);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .icon-arrow-angle-left:before {
    content: "\e908";
    color: #285d89;
  }
  .icon-arrow-angle-right:before {
    content: "\e909";
    color: #285d89;
  }
  .icon-arrow-left:before {
    content: "\e90a";
    color: #285d89;
  }
  .icon-arrow-right:before {
    content: "\e90b";
    color: #285d89;
  }
